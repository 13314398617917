import React, { useState } from 'react';
import styled from 'styled-components';
import Head from '../../components/Head';
import MainSideNav from '../../components/Nav/MainSideNav';
import HeroSection from '../../sections/hero/HeroSection';
import ChallengeSection from '../../sections/challenges/ChallengeSection';
import SolutionSection from '../../sections/solutions/SolutionSection';
import ProductSection from '../../sections/products/ProductSection';
import TeamSection from '../../sections/team/TeamSection';
import Quote from '../../components/Quote';
import PartnerSection from '../../sections/partners/PartnerSection';
import ContactSection from '../../sections/contact/ContactSection';
import { mainPageBgActiveIndex, mainPageBgIndex } from '../../utils/common';
import Lightbox from '../../components/Lightbox';

const Wrapper = styled.div`
    position: relative;
`;

const Bg = styled.div`
    width: 100%;
    height: 100%;
    background: var(--bg-tertiary-d);
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${mainPageBgIndex};
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        opacity: 1;
        z-index: ${mainPageBgActiveIndex};
    }
`;

interface Props {
    appTitle: string;
    appDesc: string;
    curView: string | null;
    curLang: string;
}

const MainPage: React.FC<Props> = ({ appTitle, appDesc, curView, curLang }) => {
    const [popupImg, setPopupImg] = useState<any>(null);
    const [isLightboxOpen, setLightboxOpen] = useState(false);

    const openLightbox = (targetImg: any) => {
        setLightboxOpen(true);
        setPopupImg(targetImg);
        document.body.classList.add('popup-open');
    };

    const closeLightbox = () => {
        setPopupImg(null);
        setLightboxOpen(false);
        document.body.classList.remove('popup-open');
    };

    return (
        <>
            <Head title={appTitle} desc={appDesc} />
            <Wrapper>
                <Bg id="main__bg-whole" />
                <Lightbox popupImg={popupImg} isLightboxOpen={isLightboxOpen} closeLightbox={closeLightbox} />
                <MainSideNav curLang={curLang} curView={curView} />
                <HeroSection curLang={curLang} />
                <ChallengeSection curLang={curLang} />
                <SolutionSection curLang={curLang} />
                <ProductSection curLang={curLang} openLightbox={openLightbox} />
                <TeamSection curLang={curLang} />
                <Quote curLang={curLang} />
                <PartnerSection />
                <ContactSection curLang={curLang} />
            </Wrapper>
        </>
    );
};

export default MainPage;
