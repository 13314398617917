import { v4 as uuidv4 } from 'uuid';
import BlubinaCoreImg from '../assets/products/blubinaCore/imgs/Blubina_Core.png';
import OrgChartsImg from '../assets/products/orgCharts/imgs/hero_en.png';
import SFSImg from '../assets/products/secureFolderSharing/imgs/hero_en.png';
import uPointImg from '../assets/products/uPointDMS/imgs/hero-en.png';
import PDFSplitterImg from '../assets/products/pdfSplitter/imgs/hero.png';

export const mainNavArr = [
    {
        id: uuidv4(),
        idx: 0,
        link: '#main__hero',
        name: 'hero',
        txt: 'common.btn.backTop',
    },
    {
        id: uuidv4(),
        idx: 1,
        link: '#main__challenges',
        name: 'challenges',
        txt: 'main.nav.challenges',
    },
    {
        id: uuidv4(),
        idx: 2,
        link: '#main__solutions',
        name: 'solutions',
        txt: 'main.nav.solutions',
    },
    {
        id: uuidv4(),
        idx: 3,
        link: '#main__products',
        name: 'products',
        txt: 'main.nav.products',
    },
    {
        id: uuidv4(),
        idx: 4,
        link: '#main__team',
        name: 'team',
        txt: 'main.nav.team',
    },
    {
        id: uuidv4(),
        idx: 5,
        link: '#main__partners',
        name: 'partners',
        txt: 'main.nav.partners',
    },
    {
        id: uuidv4(),
        idx: 6,
        link: '#main__contact',
        name: 'contact',
        txt: 'main.nav.contact',
    },
];

export const mainDetails = {
    challenge: [
        {
            id: uuidv4(),
            idx: 0,
            title: 'main.challenges.point1',
            txt: 'main.challenges.desc1',
        },
        {
            id: uuidv4(),
            idx: 1,
            title: 'main.challenges.point2',
            txt: 'main.challenges.desc2',
        },
        {
            id: uuidv4(),
            idx: 2,
            title: 'main.challenges.point3',
            txt: 'main.challenges.desc3',
        },
        {
            id: uuidv4(),
            idx: 3,
            title: 'main.challenges.point4',
            txt: 'main.challenges.desc4',
        },
        {
            id: uuidv4(),
            idx: 4,
            title: 'main.challenges.point5',
            txt: 'main.challenges.desc5',
        },
    ],
};

export const productCardList = [
    {
        id: uuidv4(),
        product: 'blubinaCore',
        title: 'product.blubinaCore.main.title',
        subtitle: 'product.blubinaCore.main.subtitle',
        description: 'product.blubinaCore.main.desc',
        img: BlubinaCoreImg,
        imgAlignLeft: true,
        tagArr: [
            { id: uuidv4(), txt: 'product.blubinaCore.tag.tag1' },
            { id: uuidv4(), txt: 'product.blubinaCore.tag.tag2' },
        ],
        hightlightArr: [
            { id: uuidv4(), txt: 'product.blubinaCore.highlight.highlight1' },
            { id: uuidv4(), txt: 'product.blubinaCore.highlight.highlight2' },
            { id: uuidv4(), txt: 'product.blubinaCore.highlight.highlight3' },
            { id: uuidv4(), txt: 'product.blubinaCore.highlight.highlight4' },
            { id: uuidv4(), txt: 'product.blubinaCore.highlight.highlight5' },
        ],
        isDiscoverMore: true,
        linkUrl: 'product/blubina-core',
    },
    {
        id: uuidv4(),
        product: 'orgCharts',
        title: 'product.orgCharts.main.title',
        subtitle: 'product.orgCharts.main.subtitle',
        description: 'product.orgCharts.main.desc',
        img: OrgChartsImg,
        imgAlignLeft: false,
        tagArr: [
            { id: uuidv4(), txt: 'product.orgCharts.tag.tag1' },
            { id: uuidv4(), txt: 'product.orgCharts.tag.tag2' },
        ],
        hightlightArr: [
            { id: uuidv4(), txt: 'product.orgCharts.highlight.highlight1' },
            { id: uuidv4(), txt: 'product.orgCharts.highlight.highlight2' },
        ],
        isDiscoverMore: true,
        linkUrl: 'product/blubina-org-charts',
    },
    {
        id: uuidv4(),
        product: 'SFS',
        title: 'product.SFS.main.title',
        subtitle: 'product.SFS.main.subtitle',
        description: 'product.SFS.main.desc',
        img: SFSImg,
        imgAlignLeft: true,
        tagArr: [
            { id: uuidv4(), txt: 'product.SFS.tag.tag1' },
            { id: uuidv4(), txt: 'product.SFS.tag.tag2' },
        ],
        hightlightArr: [
            { id: uuidv4(), txt: 'product.SFS.highlight.highlight1' },
            { id: uuidv4(), txt: 'product.SFS.highlight.highlight2' },
        ],
        isDiscoverMore: true,
        linkUrl: 'product/secure-folder-sharing',
    },
    {
        id: uuidv4(),
        product: 'uPointDMS',
        title: 'product.uPointDMS.main.title',
        subtitle: 'product.uPointDMS.main.subtitle',
        description: 'product.uPointDMS.main.desc',
        img: uPointImg,
        imgAlignLeft: false,
        tagArr: [
            { id: uuidv4(), txt: 'product.uPointDMS.tag.tag1' },
            { id: uuidv4(), txt: 'product.uPointDMS.tag.tag2' },
        ],
        hightlightArr: [{ id: uuidv4(), txt: 'product.uPointDMS.highlight.highlight1' }],
        isDiscoverMore: true,
        linkUrl: 'https://upointdms.com',
    },
    {
        id: uuidv4(),
        product: 'pdfSplitter',
        title: 'product.pdfSplitter.main.title',
        subtitle: 'product.pdfSplitter.main.subtitle',
        description: 'product.pdfSplitter.main.desc',
        img: PDFSplitterImg,
        imgAlignLeft: true,
        tagArr: [
            { id: uuidv4(), txt: 'product.pdfSplitter.tag.tag1' },
            { id: uuidv4(), txt: 'product.pdfSplitter.tag.tag2' },
        ],
        hightlightArr: [
            { id: uuidv4(), txt: 'product.pdfSplitter.highlight.highlight1' },
            { id: uuidv4(), txt: 'product.pdfSplitter.highlight.highlight2' },
        ],
        isDiscoverMore: true,
        linkUrl: 'product/pdf-splitter',
    },
];
