import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardLayout, StyledWrapperStandard, TitleWrapper } from '../../components/common.style';
import { H4Title, Text1 } from '../../components/common.styles.font';
import CardProduct from '../../components/CardProduct';
import { productCardList } from '../../data/mainDetails';
import { IntroWrapper } from '../productPricing/style';

interface Props {
    curLang: string;
    openLightbox: (arg0: any) => void;
}

const ProductSection: React.FC<Props> = ({ curLang, openLightbox }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard id="main__products" className="bg-tertiary">
            <TitleWrapper className="title-intro-wrapper">
                <H4Title className="title-span-tertiary" data-aos-delay="80" data-aos="fade-up">
                    <span>{t('main.products.title')}</span>
                </H4Title>
            </TitleWrapper>

            <IntroWrapper>
                <Text1>{t('main.products.intro')}</Text1>
            </IntroWrapper>

            <ProductCardLayout>
                {productCardList.map(({ id, product, title, subtitle, description, img, imgAlignLeft, tagArr, hightlightArr, isDiscoverMore, linkUrl }) => (
                    <CardProduct key={id} curLang={curLang} img={img} imgAlignLeft={imgAlignLeft} tagArr={tagArr} hightlightArr={hightlightArr} title={t(title)} subtitle={t(subtitle)} description={t(description)} isDiscoverMore={isDiscoverMore} link={product === 'uPointDMS' ? `${linkUrl}/${curLang}` : `/${curLang}/${linkUrl}`} openLightbox={openLightbox} />
                ))}
            </ProductCardLayout>
        </StyledWrapperStandard>
    );
};

export default ProductSection;
